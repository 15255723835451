import {
	AspectRatio,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import { IconContext } from "react-icons";
import { IoLogoYoutube } from "react-icons/io";
import { useSelector } from "react-redux";

export default function TrailerModalButton() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const trailer = useSelector((state) => state.series.trailer);

	function YoutubeButton() {
		return (
			<IconContext.Provider value={{ size: "70px" }}>
				<div>
					<IoLogoYoutube style={{ fill: "red" }} />
				</div>
			</IconContext.Provider>
		);
	}

	return (
		<>
			<IconButton
				_focus={{ boxShadow: "none" }}
				bg="white"
				size="xs"
				onClick={onOpen}
				icon={<YoutubeButton />}
			></IconButton>

			<Modal onClose={onClose} size="4xl" isOpen={isOpen} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody bg="gray.200">
						<AspectRatio mt="10">
							<iframe
								src={`https://www.youtube.com/embed/${trailer.key}`}
								title="Series Trailer"
								allowFullScreen
							></iframe>
						</AspectRatio>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

import { Button } from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearUserState } from "../store/userSlice";
import { clearWatchlistState } from "../store/watchlistSlice";

export default function SignOutButton() {
	const dispatch = useDispatch();
	let history = useHistory();

	return (
		<Button
			onClick={() => {
				localStorage.removeItem("token");
				dispatch(clearUserState());
				dispatch(clearWatchlistState());
				history.push(`/`);
			}}
			colorScheme="primary"
		>
			Signout
		</Button>
	);
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SignUpForm from "../components/SignUpForm";
import { auth, setLoading, signUp } from "../store/userSlice";

export default function SignUp() {
	let history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);
	const loading = useSelector((state) => state.user.loading);

	useEffect(() => {
		if (user.token && user.status.success) {
			console.log(user);
			history.push(`/trending`);
			dispatch(auth(true));
		}
		if (!user.token && user.status.error) {
			console.log(user.status.error);
		}
	}, [user, history, dispatch]);

	const onSubmit = async (values) => {
		if (values) {
			dispatch(setLoading(true));
			dispatch(signUp(values));
		}
	};

	return <SignUpForm onSubmit={onSubmit} loading={loading} responseError={user.status} />;
}

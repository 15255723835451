import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SeriesListView from "../components/SeriesListView";
import { getWatchlistData } from "../store//watchlistSlice";

export default function WatchlistSeries(props) {
	const dispatch = useDispatch();
	const watchlistID = props.match.params.watchlistID;

	useEffect(() => {
		dispatch(getWatchlistData(watchlistID));
	}, [watchlistID, dispatch]);

	const watchlist = useSelector((state) => state.watchlist.selectedWatchlist);
	return (
		<div>
			<SeriesListView
				header={watchlist.name}
				seriesList={watchlist ? watchlist.seriesList : null}
				isWatchlist
			/>
		</div>
	);
}

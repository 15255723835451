import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SeriesListView from "../components/SeriesListView";
import { setSearchTerm, updateSearchResult } from "../store//searchSlice";

export default function SearchResult(props) {
	const dispatch = useDispatch();
	const term = props.match.params.searchTerm;

	useEffect(() => {
		dispatch(setSearchTerm(term));
		dispatch(updateSearchResult(term));
	}, [term, dispatch]);

	const searchResult = useSelector((state) => state.search.searchResult);
	return (
		<div>
			<SeriesListView
				header={`Search Result for ${term}`}
				seriesList={searchResult ? searchResult.results : null}
			/>
		</div>
	);
}

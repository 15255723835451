import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
	colors: {
		primary: {
			50: "##F7FAFC",
			100: "#EDF2F7",
			200: "#E2E8F0",
			300: "#CBD5E0",
			400: "#A0AEC0",
			500: "#718096",
			600: "#4A5568",
			700: "#2D3748",
			800: "#1A202C",
			900: "#171923",
		},
		primaryBgColor: "#171923",
		primaryColor: "#1A202C",
		secondaryColor: "#2D3748",
		primaryTextColor: "white",
	},
	textStyles: {
		p: {
			fontColor: "white",
		},
	},
	components: {
		Button: {
			baseStyle: {
				_focus: { boxShadow: "none" },
			},
			sizes: {
				xl: {
					h: "56px",
					fontSize: "lg",
					px: "32px",
				},
			},
		},
		Drawer: {
			baseStyle: {
				dialog: { bg: "#2d3748" },
				header: { bg: "#171923", color: "#E2E8F0" },
			},
		},
	},
});

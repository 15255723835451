import { Box, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";

export default function SeriesDetailsSkeleton() {
	return (
		<Flex
			direction="column"
			justify="center"
			alignItems="center"
			mx={{ lg: "2vw" }}
			bg="primary.400"
			w="91vw"
		>
			<Flex
				w="89vw"
				justify={{ base: "start", lg: "start" }}
				alignItems={{ base: "start", lg: "start" }}
				direction="row"
				grow="1"
				bg="primary.400"
			>
				<Box
					pl="2"
					py="5"
					ml="2"
					my="5"
					minW="sm"
					maxW="md"
					alignItems="start"
					mr={{ md: "14", lg: "10" }}
				>
					<Skeleton height="650px" w="450px" />
				</Box>
				<Flex p="4" m="3" pr="2vw">
					<Box padding="6" boxShadow="lg" minW={{ base: "sm", lg: "xl" }} w="auto" grow="1">
						<SkeletonText mt="4" noOfLines={25} spacing="4" />
					</Box>
				</Flex>
			</Flex>

			{[...Array(4)].map(() => (
				<Flex w="89vw" mt="6" mx={{ md: "6vw", lg: "3vw" }} bg="gray.500">
					<Skeleton height="250px" m="6" bg="black" />
				</Flex>
			))}
		</Flex>
	);
}

import { Box, Container, Stack, Text, useColorModeValue } from "@chakra-ui/react";

export default function Footer() {
	return (
		<Box
			bg={useColorModeValue("primaryColor", "")}
			color={useColorModeValue("primaryTextColor", "gray.200")}
		>
			<Container
				as={Stack}
				maxW="6xl"
				py={4}
				direction={{ base: "column", md: "row" }}
				spacing={4}
				justify="center"
				align="center"
			>
				<Text>© 2021 Serisify. All rights reserved</Text>
			</Container>
		</Box>
	);
}

import { EditIcon } from "@chakra-ui/icons";
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import { default as React, useState } from "react";
import { useDispatch } from "react-redux";
import { renameWatchlist } from "../store/watchlistSlice";

export default function RenameWatchlist({ oldName, watchlistID }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = React.useRef();
	const dispatch = useDispatch();
	const [watchlistName, setWatchlistName] = useState(oldName);

	const handleSave = (name) => {
		if (watchlistName) {
			dispatch(renameWatchlist({ name: watchlistName, watchlistID: watchlistID }));
			onClose();
		}
	};

	return (
		<>
			<Button
				colorScheme="primary"
				aria-label="Rename a Watchlist"
				size="md"
				leftIcon={<EditIcon />}
				onClick={onOpen}
				variant="outline"
			>
				Rename
			</Button>
			<Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Rename Watchlist</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={5}>
						<FormControl>
							<FormLabel>Watchlist Name</FormLabel>
							<Input
								value={watchlistName}
								onChange={(e) => setWatchlistName(e.target.value)}
								ref={initialRef}
								placeholder="Watchlist Name"
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={handleSave} _focus={{ boxShadow: "none" }}>
							Rename
						</Button>
						<Button
							onClick={() => {
								setWatchlistName(oldName);
								onClose();
							}}
						>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

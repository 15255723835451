import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt from "jwt-decode";
import seriesify from "../api/seriesify";

export const signIn = createAsyncThunk("user/login", async (params, { dispatch }) => {
	const response = await seriesify.post(`/auth/login`, params);
	if (response.data.success) {
		const token = response.data.token;
		const user = jwt(token);
		seriesify.defaults.headers.common["jwt-token"] = token;
		localStorage.setItem("token", token);
		// dispatch(auth(true));
		return {
			user: user,
			token: token,
			status: { success: response.data.success },
		};
	} else {
		return { status: response.data };
	}
});

export const signUp = createAsyncThunk("user/signup", async (params, { dispatch }) => {
	const response = await seriesify.post(`/auth/signup`, params);
	if (response.data.success) {
		seriesify.defaults.headers.common["jwt-token"] = response.data.token;
		localStorage.setItem("token", response.data.token);
		return {
			user: response.data.user,
			token: response.data.token,
			status: { success: response.data.success },
		};
	} else {
		return { status: response.data };
	}
});

export const renameWatchlist = createAsyncThunk("watchlist/renameWatchlist", async (params) => {
	const response = await seriesify.post(`/watchlist/${params.watchlistID}`, { name: params.name });
	return response.data;
});

export const deleteWatchlist = createAsyncThunk("watchlist/deleteWatchlist", async (params) => {
	await seriesify.delete(`/watchlist/${params}`);
	return params;
});

export const removeSeriesFromWatchlist = createAsyncThunk(
	"watchlist/removeSeriesFromWatchlist",
	async (params) => {
		await seriesify.delete(`/watchlist/${params.watchlistID}/series/${params.seriesID}`);
		return params;
	}
);

const initialState = () => {
	return {
		data: {},
		token: null,
		status: {
			success: null,
			error: "",
		},
		authenticated: false,
		loading: false,
	};
};

const userSlice = createSlice({
	name: "user",
	initialState: initialState(),
	reducers: {
		clearUserState: (state) => initialState(),
		updateUserFromStorage: (state, action) => {
			state.data = action.payload.user ? action.payload.user.user : {};
			state.token = action.payload.token;
			state.authenticated = true;
		},
		auth: (state, action) => {
			state.authenticated = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
	},

	extraReducers: {
		[signUp.fulfilled]: (state, action) => {
			state.data = action.payload.user;
			state.token = action.payload.token;
			state.status = action.payload.status;
			state.loading = false;
		},
		[signIn.fulfilled]: (state, action) => {
			state.data = action.payload.user ? action.payload.user.user : {};
			state.token = action.payload.token;
			state.status = action.payload.status;
			state.loading = false;
		},
	},
});

export const { clearUserState, updateUserFromStorage, auth, setLoading } = userSlice.actions;

export default userSlice.reducer;

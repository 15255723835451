import { Flex, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SeriesCard from "../components/SeriesCard";
import { getAllWatchlist } from "../store/watchlistSlice";
import WatchlsitSeriesCard from "./series/WatchlistSeriesCard";
import SeriesListSkeleton from "./skeletons/SeriesListSkeleton";

const RenderSeriesList = ({ header, seriesList, watchlists, isWatchlist }) =>
	seriesList ? (
		<div>
			<Flex direction="column" justifyContent="start" mx={{ md: "6vw", lg: "3vw" }} wrap="wrap">
				<Text
					as="h1"
					color="white"
					fontSize={["2xl", "3xl", "4xl", "4xl", "4xl"]}
					fontWeight="bold"
				>
					{header}
				</Text>
				<Spacer />
				<Flex
					direction="row"
					justifyContent="spaceAround"
					// mx={{ md: "6vw", lg: "3vw" }}
					wrap="wrap"
					// _after={{content: `""`, flex: "auto"}}
				>
					{seriesList.map((series) =>
						isWatchlist ? (
							<WatchlsitSeriesCard series={series} key={series.id} watchlists={watchlists} />
						) : (
							<SeriesCard series={series} key={series.id} watchlists={watchlists} />
						)
					)}
				</Flex>
			</Flex>
		</div>
	) : (
		<SeriesListSkeleton header={header} />
	);

export default function SeriesListView({ header, seriesList, isWatchlist }) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const watchlists = useSelector((state) => state.watchlist.all.watchlists);

	useEffect(() => {
		if (!watchlists && user.authenticated) {
			dispatch(getAllWatchlist(user.data._id));
		}
	}, [dispatch, user, watchlists]);

	return (
		<div>
			<RenderSeriesList
				header={header}
				seriesList={seriesList}
				watchlists={watchlists}
				isWatchlist={isWatchlist}
			/>
		</div>
	);
}

import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function CastCard({ actor }) {
	return actor ? (
		<Flex px={2} pb="4" w={["150px"]} justifyContent="center">
			<Box bg={"primary.300"} w="150px" borderWidth="1px" shadow="lg">
				<Image
					h="150px"
					w="150px"
					src={`https://image.tmdb.org/t/p/w185/${actor.profile_path}`}
					alt={`Picture of ${actor.name}`}
					align="50% 50%"
				/>

				<VStack justifyContent="start" spacing="0">
					<Box fontWeight="bold" as="h4" lineHeight="tight" maxW="140px">
						<Text fontSize={["xs"]} isTruncated maxW="145px">
							{actor.name}
						</Text>
					</Box>
					<Flex wrap="wrap" maxW="145px">
						<Text fontSize={["xs"]}>as {actor.character}</Text>
					</Flex>
				</VStack>
			</Box>
		</Flex>
	) : (
		"Loading.."
	);
}

import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Flex, Stack, Text } from "@chakra-ui/react";
import { default as React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteWatchlist, getAllWatchlist } from "../store/watchlistSlice";
import RenameWatchlist from "./RenameWatchlist";

export default function ShowWatchlists() {
	const dispatch = useDispatch();
	const userID = useSelector((state) => state.user.data._id);
	const watchlists = useSelector((state) => state.watchlist.all.watchlists);

	useEffect(() => {
		if (!watchlists && userID) {
			dispatch(getAllWatchlist(userID));
		}
	}, [dispatch, userID, watchlists]);

	return (
		<div>
			{watchlists
				? watchlists.map((watchlist) => (
						<Box
							rounded="lg"
							key={watchlist._id}
							width="60vw"
							bgColor="primary.400"
							mb="10"
							alignItems="center"
						>
							<Flex justify="space-between" px="5" py="2" direction={{ base: "column", lg: "row" }}>
								<Center>
									<Link to={`/watchlists/${watchlist._id}`}>
										<Text
											as="h4"
											fontSize={["2xl", "2xl", "2xl", "2xl", "2xl"]}
											// fontWeight="bold"
											color="primary.800"
										>
											{watchlist.name}
										</Text>
									</Link>{" "}
								</Center>

								<Flex justify="center" alignItems="center" p="3">
									<Stack direction={["column", "row"]} spacing="24px">
										<RenameWatchlist oldName={watchlist.name} watchlistID={watchlist._id} />
										<Button
											leftIcon={<DeleteIcon />}
											onClick={() => {
												dispatch(deleteWatchlist(watchlist._id));
											}}
											colorScheme="red"
											variant="outline"
										>
											Delete
										</Button>
									</Stack>
								</Flex>
							</Flex>
						</Box>
				  ))
				: "Loading....."}
		</div>
	);
}

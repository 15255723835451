import { Route } from "react-router-dom";
import SearchResult from "../pages/SearchResult";
import Series from "../pages/Series";
import Trending from "../pages/Trending";
import Watchlists from "../pages/Watchlists";
import WatchlistSeries from "../pages/WatchlistSeries";

export const ProtectedRoutes = () => {
	return (
		<div>
			<Route path="/" exact component={Trending}></Route>
			<Route path="/trending" exact component={Trending}></Route>
			<Route path="/search/:searchTerm" exact component={SearchResult}></Route>
			<Route path="/watchlists" exact component={Watchlists}></Route>
			<Route path="/watchlists/:watchlistID" exact component={WatchlistSeries}></Route>
			<Route path="/series/:tmdbID" exact component={Series}></Route>
		</div>
	);
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SeriesListView from "../components/SeriesListView";
import { getTrending } from "../store/trendingSlice";

export default function Trending() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getTrending());
	}, [dispatch]);

	const trending = useSelector((state) => state.trending);

	return (
		<div>
			<SeriesListView header="Trending" seriesList={trending.results ? trending.results : null} />
		</div>
	);
}

import { Box } from "@chakra-ui/react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

export default function Rating({ vote_average, vote_count, stars }) {
	return (
		<Box d="flex" alignItems="center" pt="1">
			{Array(stars)
				.fill("")
				.map((_, i) => {
					const roundedRating = stars === 5 ? Math.round(vote_average) / 2 : vote_average;
					if (roundedRating - i >= 1) {
						return (
							<BsStarFill
								key={i}
								style={{ marginLeft: "1" }}
								color={i < vote_average ? "teal.500" : "gray.300"}
							/>
						);
					}
					if (roundedRating - i > 0.38 && roundedRating - i < 0.6) {
						return <BsStarHalf key={i} style={{ marginLeft: "1" }} />;
					}
					return <BsStar key={i} style={{ marginLeft: "1" }} />;
				})}
			<Box as="span" ml="2" color="gray.600" fontSize="xs">
				{vote_count} rating{vote_count > 1 && "s"}
			</Box>
		</Box>
	);
}

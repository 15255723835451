import { Box, Button, Flex, Heading, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import InputFinal from "../components/InputFinal";

export default function SignUpForm({ onSubmit, loading, responseError }) {
	const {
		register,
		handleSubmit,
		formState: { errors, touchedFields },
		watch,
	} = useForm();

	const password = watch("password", "");
	console.log(password);
	return (
		<Flex align={"center"} justify={"center"}>
			<Stack
				spacing={8}
				mx={"auto"}
				maxW={"lg"}
				py={12}
				px={6}
				bg={useColorModeValue("gray.50", "gray.800")}
			>
				<Stack align={"center"}>
					<Heading fontSize={"2xl"} color={"gray.600"}>
						Sign Up to create watchlists! ✌️
					</Heading>
				</Stack>
				<Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
					<Stack spacing={4}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<InputFinal
								type="text"
								label="First Name"
								isRequired={true}
								placeholder="First Name"
								touched={touchedFields.firstName ? true : false}
								error={errors.firstName}
								rhfInputs={{
									...register("firstName", {
										required: "First Name is required.",
									}),
								}}
							/>
							<InputFinal
								type="text"
								label="Last Name"
								placeholder="Last Name"
								rhfInputs={{ ...register("lastName") }}
								isRequired={false}
							/>
							<InputFinal
								type="email"
								label="Email"
								isRequired={true}
								rhfInputs={{
									...register("email", { required: "Email is required!", maxLength: 80 }),
								}}
								placeholder="Email"
								touched={touchedFields.email ? true : false}
								error={errors.email}
							/>
							<InputFinal
								type="password"
								label="Password"
								placeholder="Password"
								rhfInputs={{
									...register("password", {
										required: "Password required",
										minLength: { value: 6, message: "Password must be minimum 6 characters long!" },
									}),
								}}
								isRequired={true}
								touched={touchedFields.password ? true : false}
								error={errors.password}
							/>
							<InputFinal
								type="password"
								label="Confirm Password"
								placeholder="Confirm Password"
								rhfInputs={{
									...register("confirmPassword", {
										required: true,
										minLength: 6,
										validate: (value) => value === password || "The passwords must match!",
									}),
								}}
								isRequired={true}
								touched={touchedFields.confirmPassword ? true : false}
								error={errors.confirmPassword}
							/>
							<Stack>
								{!responseError.success && !loading ? (
									<Text color="red" pb="4">
										{responseError.error}
									</Text>
								) : (
									""
								)}
								<Button
									bg={"blue.400"}
									color={"white"}
									_hover={{
										bg: "blue.500",
									}}
									type="submit"
									isLoading={loading}
									loadingText="Signing Up"
								>
									Sign Up
								</Button>
							</Stack>
						</form>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	);
}

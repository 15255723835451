import { Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { auth, setLoading, signIn } from "../store/userSlice";

export default function SignIn() {
	let history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);
	const loading = useSelector((state) => state.user.loading);

	useEffect(() => {
		if (user.token && user.status.success) {
			console.log(user);
			history.push(`/trending`);
			dispatch(auth(true));
		}
		if (!user.token && user.status.error) {
			console.log(user.status.error);
		}
	}, [user, history, dispatch]);

	const onSubmit = async (values) => {
		dispatch(setLoading(true));
		dispatch(
			signIn({
				email: "anonymous@gmail.com",
				password: "123456",
			})
		);
	};

	return (
		<Button onClick={onSubmit} isLoading={loading}>
			SignIn Anonymously
		</Button>
	);
}

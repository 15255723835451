import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Text, useDisclosure, VStack } from "@chakra-ui/react";
import React from "react";
import LatestEpisodesCard from "./LatestEpisodesCard";

export default function LatestEpisodesDropdown({ episodes }) {
	const { isOpen, onToggle } = useDisclosure();
	return (
		<Flex w="89vw" mt="6" mx={{ md: "6vw", lg: "3vw" }}>
			<VStack>
				<Box
					fontWeight="bold"
					mr="auto"
					mb="1"
					bg="primary.400"
					w="89vw"
					h={{ base: "50px", lg: "50px" }}
					onClick={onToggle}
					alignItems="center"
					justifyContent="start"
					d="flex"
					as="button"
					border="2px"
					borderColor="primary.600"
				>
					{!isOpen ? (
						<ChevronDownIcon mx="2" boxSize="8" />
					) : (
						<ChevronRightIcon mx="2" boxSize="8" />
					)}
					<Text fontSize={["lg", "xl"]}>Latest Episodes</Text>
				</Box>
				<Collapse in={!isOpen} animateOpacity>
					<Flex w="89vw" bg="primary.400" px="2" dir={{ base: "column", lg: "row" }} wrap="wrap">
						<LatestEpisodesCard name="Next Episode" episode={episodes.next_episode_to_air} />
						<LatestEpisodesCard name="Last Episode" episode={episodes.last_episode_to_air} />
					</Flex>
				</Collapse>
			</VStack>
		</Flex>
	);
}

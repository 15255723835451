// Import Swiper styles
import "swiper/components/navigation/navigation.min.css";
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper } from "swiper/react";
import "swiper/swiper.min.css";
import "./carouselMulti.css";

// install Swiper modules
SwiperCore.use([Navigation]);

export default function CarouselMulti({ children, breakpoints }) {
	return (
		<>
			<Swiper spaceBetween={5} breakpoints={breakpoints} navigation={true} className="mySwiper">
				{children}
			</Swiper>
		</>
	);
}

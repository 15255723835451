import { Flex, Skeleton, Spacer, Text } from "@chakra-ui/react";
import React from "react";

export default function SeriesListSkeleton({ header }) {
	return (
		<div>
			<Flex direction="column" justifyContent="start" mx={{ md: "6vw", lg: "3vw" }} wrap="wrap">
				<Text
					as="h1"
					color="white"
					fontSize={["2xl", "3xl", "4xl", "4xl", "4xl"]}
					fontWeight="bold"
					mb="6"
				>
					{header}
				</Text>
				<Spacer />
				<Flex direction="row" justifyContent="spaceAround" wrap="wrap">
					{[...Array(20)].map(() => (
						<Skeleton height="500px" width="300px" m="4" />
					))}
				</Flex>
			</Flex>
		</div>
	);
}

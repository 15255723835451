import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Text, useDisclosure, VStack } from "@chakra-ui/react";
import React from "react";
import { SwiperSlide } from "swiper/react";
import CarouselMulti from "../../carousel/CarouselMulti";
import SimilarSeriesCard from "./SimilarSeriesCard";

export default function SimilarSeriesCrousel({ similars, watchlists }) {
	const { isOpen, onToggle } = useDisclosure();
	const breakpoints = {
		640: {
			slidesPerView: 4,
			spaceBetween: 40,
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 50,
		},
		1200: {
			slidesPerView: 5,
			spaceBetween: 40,
		},
		1600: {
			slidesPerView: 7,
			spaceBetween: 95,
		},
	};
	return (
		<Flex bg="primary.400" w="89vw" my="6" mx={{ md: "6vw", lg: "3vw" }}>
			<VStack>
				<Box
					fontWeight="bold"
					mr="auto"
					bg="primary.400"
					w="89vw"
					h={{ base: "50px", lg: "50px" }}
					onClick={onToggle}
					alignItems="center"
					justifyContent="start"
					d="flex"
					as="button"
					border="2px"
					borderColor="primary.600"
				>
					{!isOpen ? (
						<ChevronDownIcon mx="2" boxSize="8" />
					) : (
						<ChevronRightIcon mx="2" boxSize="8" />
					)}
					<Text fontSize={["lg", "xl"]}>Similar Series</Text>
				</Box>
				<Collapse in={!isOpen} animateOpacity>
					<Flex
						w="89vw"
						maxW="89vw"
						bg="primary.400"
						px="2"
						pt="4"
						alignContent="stretch"
						alignItems="stretch"
					>
						<CarouselMulti breakpoints={breakpoints}>
							{similars
								? similars.map((series) => {
										return (
											<SwiperSlide>
												<SimilarSeriesCard series={series} watchlists={watchlists} />
											</SwiperSlide>
										);
								  })
								: "Loading.."}
						</CarouselMulti>
					</Flex>
				</Collapse>
			</VStack>
		</Flex>
	);
}

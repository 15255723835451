import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import seriesify from "../api/seriesify";

export const updateSearchResult = createAsyncThunk("search/searchResult", async (payload) => {
	const response = await seriesify.get(`/tmdb/search/${payload}/1`);
	return response.data;
});

const searchSlice = createSlice({
	name: "search",
	initialState: {
		searchTerm: "",
		searchResult: {},
	},

	reducers: {
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
		},
	},
	extraReducers: {
		[updateSearchResult.fulfilled]: (state, action) => {
			state.searchResult = action.payload;
		},
	},
});

export const { setSearchTerm } = searchSlice.actions;

export default searchSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import seriesify from "../api/seriesify";

export const getAllWatchlist = createAsyncThunk("watchlist/getAllWatchlist", async (params) => {
	const response = await seriesify.get(`/watchlist/all/user/${params}`);
	return response.data;
});

export const getWatchlistData = createAsyncThunk("watchlist/getWatchlistData", async (params) => {
	const response = await seriesify.get(`/watchlist/${params}`);
	return response.data;
});

export const createWatchlist = createAsyncThunk(
	"watchlist/createWatchlist",
	async (params, { dispatch }) => {
		const response = await seriesify.post(`/watchlist`, params);
		dispatch(getAllWatchlist(params.userID));
		return response.data;
	}
);

export const renameWatchlist = createAsyncThunk(
	"watchlist/renameWatchlist",
	async (params, { dispatch }) => {
		await seriesify.patch(`/watchlist/${params.watchlistID}`, {
			name: params.name,
		});
		return params;
	}
);

export const deleteWatchlist = createAsyncThunk("watchlist/deleteWatchlist", async (params) => {
	await seriesify.delete(`/watchlist/${params}`);
	return params;
});

export const removeSeriesFromWatchlist = createAsyncThunk(
	"watchlist/removeSeriesFromWatchlist",
	async (params) => {
		await seriesify.delete(`/watchlist/${params.watchlistID}/series/${params.seriesID}`);
		return params;
	}
);

export const addSeriesToWatchlist = createAsyncThunk(
	"watchlist/addSeriesToWatchlist",
	async (params, { dispatch }) => {
		const response = await seriesify.post(`/watchlist/${params.watchlistID}/series`, params.series);
		// dispatch(getAllWatchlist(params.userID));

		if (response.data.success) return { data: params, response: response.data };
		return response.data;
	}
);

const initialState = () => {
	return {
		all: [],
		selectedWatchlist: {},
		seriesAdded: "",
		response: {},
		loadingWatchlist: false,
	};
};

const watchlistSlice = createSlice({
	name: "watchlist",
	initialState: initialState(),

	reducers: {
		clearWatchlistState: (state) => initialState(),
	},

	extraReducers: {
		[getAllWatchlist.fulfilled]: (state, action) => {
			state.all = action.payload;
			state.loadingWatchlist = false;
		},
		[getWatchlistData.fulfilled]: (state, action) => {
			state.selectedWatchlist = action.payload.watchlist;
			state.response.success = action.payload.success;
		},
		[createWatchlist.fulfilled]: (state, action) => {
			// state.all = action.payload;
		},
		[renameWatchlist.fulfilled]: (state, action) => {
			state.all.watchlists = state.all.watchlists.filter((watchlist) => {
				if (watchlist._id === action.payload.watchlistID) {
					watchlist.name = action.payload.name;
				}
				return watchlist;
			});
		},
		[deleteWatchlist.fulfilled]: (state, action) => {
			state.all.watchlists = state.all.watchlists.filter(
				(watchlist) => watchlist._id !== action.payload
			);
		},
		[removeSeriesFromWatchlist.fulfilled]: (state, action) => {
			const updatedWatchlists = state.all.watchlists.filter((watchlist) => {
				if (watchlist._id === action.payload.watchlistID) {
					watchlist.seriesList = watchlist.seriesList.filter(
						(s) => s.tmdbID !== action.payload.seriesID
					);
					state.selectedWatchlist = watchlist;
					return watchlist;
				}
				return watchlist;
			});
			state.all.watchlists = updatedWatchlists;
		},
		[addSeriesToWatchlist.fulfilled]: (state, action) => {
			state.seriesAdded = action.payload.data.series.tmdbID;
			state.response = action.payload.response;
			state.all.watchlists.filter((watchlist) => {
				if (watchlist._id === action.payload.data.watchlistID) {
					watchlist.seriesList.push(action.payload.data.series);
					return true;
				}
				return false;
			});
		},
	},
});

export const { clearWatchlistState } = watchlistSlice.actions;

export default watchlistSlice.reducer;

import { Badge, Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Rating from "./RatingView";
import RemoveSeriesButton from "./RemoveSeriesButton";

function WatchlsitSeriesCard({ series, watchlists }) {
	return (
		<Flex p={6} w={["xs"]} justifyContent="start" grow="1" basis="xs" maxW="sm">
			<Box
				bg={useColorModeValue("primary.300", "gray.800")}
				w="xs"
				borderWidth="1px"
				roundedBottom="lg"
				shadow="lg"
				position="relative"
				onClick={() => {
					console.log(series.id);
				}}
			>
				{(Date.now() - new Date(series.first_air_date)) / (1000 * 60 * 60 * 24) < 70 ? (
					<Box d="flex" alignItems="baseline">
						<Badge
							rounded="full"
							px="2"
							fontSize="0.8em"
							colorScheme="red"
							top={4}
							left={4}
							position="absolute"
						>
							New
						</Badge>
					</Box>
				) : (
					""
				)}
				<Link to={`/series/${series.tmdbID}`}>
					<Image
						minH="sm"
						src={
							series.poster_path
								? `https://image.tmdb.org/t/p/w342/${series.poster_path}`
								: "https://image.tmdb.org/t/p/w342//lJA2RCMfsWoskqlQhXPSLFQGXEJ.jpg"
						}
						alt={`Picture of ${series.name}`}
					/>
				</Link>
				<Box pt="2" pb="0" px="5">
					<Flex justifyContent="space-between" alignContent="center">
						<Link to={`/series/${series.tmdbID}`}>
							<Box fontWeight="bold" as="h4" lineHeight="tight" maxW="2xs">
								<Text
									fontSize={["lg", "xl", "2xl"]}
									// noOfLines={1}
									isTruncated
								>
									{series.name}
								</Text>
							</Box>
						</Link>
					</Flex>

					<Flex justifyContent="space-between" alignContent="center">
						<Rating vote_average={series.vote_average} vote_count={series.vote_count} stars={5} />
					</Flex>
					<Flex p="3" justify="center" alignItems="center">
						{watchlists ? <RemoveSeriesButton watchlists={watchlists} series={series} /> : ""}
					</Flex>
				</Box>
			</Box>
		</Flex>
	);
}

export default WatchlsitSeriesCard;

import { WarningTwoIcon } from "@chakra-ui/icons";
import { FormControl, FormHelperText, FormLabel, HStack, Input } from "@chakra-ui/react";
import React from "react";

export default function InputFinal({ rhfInputs, ...props }) {
	return (
		<FormControl isRequired={props.isRequired} pb="2">
			<FormLabel>{props.label}</FormLabel>
			<Input {...rhfInputs} type={props.type} placeholder={props.placeholder} />

			{props.error && props.touched ? (
				<HStack alignItems="center" pt="2">
					<WarningTwoIcon color="red.500" />
					<FormHelperText color="red">{props.error.message}</FormHelperText>
				</HStack>
			) : (
				""
			)}
		</FormControl>
	);
}

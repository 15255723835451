import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import seriesify from "../api/seriesify";

export const getSeries = createAsyncThunk("series/getSeries", async (params, { dispatch }) => {
	dispatch(setLoading(true));

	const response = await seriesify.get(`/tmdb/series/${params}`);
	return response.data;
});

export const getTrailer = createAsyncThunk("series/getTrailer", async (params, { dispatch }) => {
	const response = await seriesify.get(`/tmdb/series/${params}/videos`);
	let trailer;
	response.data.results.some((video) => {
		if (video.type === "Trailer") {
			trailer = video;
			return true;
		}
		return false;
	});
	return trailer;
});

export const getCast = createAsyncThunk("series/getCast", async (params, { dispatch }) => {
	const response = await seriesify.get(`/tmdb/series/${params}/credits`);
	return response.data;
});

export const getSimilar = createAsyncThunk("series/getSimilar", async (params, { dispatch }) => {
	const response = await seriesify.get(`/tmdb/series/${params}/recommendations`);
	return response.data;
});

const seriesSlice = createSlice({
	name: "series",
	initialState: {
		loading: false,
		data: {},
		cast: [],
		similars: [],
		trailer: {},
	},
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
	},
	extraReducers: {
		[getSeries.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
		},
		[getTrailer.fulfilled]: (state, action) => {
			state.trailer = action.payload;
		},
		[getCast.fulfilled]: (state, action) => {
			state.cast = action.payload.cast;
		},
		[getSimilar.fulfilled]: (state, action) => {
			state.similars = action.payload.results;
		},
	},
});
export const { setLoading } = seriesSlice.actions;

export default seriesSlice.reducer;

import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { default as React } from "react";
import Rating from "../RatingView";

export default function LatestEpisodesCard({ episode, name }) {
	const airDate = episode ? new Date(episode.air_date).toDateString().slice(4) : "";

	const episodeNumber = episode
		? "S" +
		  (episode.season_number < 10 ? "0" + episode.season_number : episode.season_number) +
		  "E" +
		  (episode.episode_number < 10 ? "0" + episode.episode_number : episode.episode_number)
		: "";

	return episode ? (
		<Flex
			justify={{ base: "start", lg: "start" }}
			alignItems={{ base: "start" }}
			direction="row"
			bg="primary.400"
			p="2"
			w={{ base: "80vw", lg: "42vw" }}
		>
			<VStack w={{ lg: "42vw" }} alignItems="start">
				<Box fontWeight="bold" as="h4">
					<Text fontSize={["lg"]}>
						{name}: {episodeNumber}
					</Text>
				</Box>
				<HStack alignItems="start" w={{ base: "80vw", lg: "42vw" }}>
					<Image
						h="200px"
						w="300px"
						src={`https://image.tmdb.org/t/p/w300/${episode.still_path}`}
						alt={
							episode.still_path
								? `Picture of ${episode.name}`
								: `Picture of next episode not available yet!`
						}
						align="50% 50%"
						border="1px"
						borderColor="primary.200"
					/>

					<Flex lineHeight="tight" pl="2" wrap="wrap">
						<VStack alignItems="start" wrap="wrap">
							<Box fontWeight="bold" as="h4" mr="auto">
								<Text fontSize={["lg"]}>{episode.name}</Text>
							</Box>
							<Rating
								vote_average={episode.vote_average}
								vote_count={episode.vote_count}
								stars={10}
							></Rating>
							<Text fontSize={["lg"]}>
								<b>Overview:</b> {episode.overview}
							</Text>
							<Text fontSize={["lg"]}>
								<b>Episode No. :</b> {episodeNumber}
							</Text>
							<Text fontSize={["lg"]}>
								<b>Air Date:</b> {airDate}
							</Text>
						</VStack>
					</Flex>
				</HStack>
			</VStack>
		</Flex>
	) : (
		""
	);
}

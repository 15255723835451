import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
	Button,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	Stack,
	Text,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addSeriesToWatchlist, removeSeriesFromWatchlist } from "../../store//watchlistSlice";

export default function RemoveSeriesButton({ watchlists, series }) {
	const dispatch = useDispatch();
	const { watchlistID } = useParams();

	const userID = useSelector((state) => state.user.data._id);

	const moveSeriesObj = (wid) => {
		return {
			watchlistID: wid,
			userID: userID,
			series: series,
		};
	};

	const handleRemoveSeries = () => {
		dispatch(removeSeriesFromWatchlist({ seriesID: series.tmdbID, watchlistID: watchlistID }));
	};

	const handleMoveSeries = (watchlist) => {
		dispatch(addSeriesToWatchlist(moveSeriesObj(watchlist._id)));
		dispatch(removeSeriesFromWatchlist({ seriesID: series.tmdbID, watchlistID: watchlistID }));
	};

	const renderPopoverButtons = (watchlist) => {
		const exists = Boolean(
			watchlist.seriesList.find((s) => {
				// console.log(series.tmdbID + "<< series.tmdbID" + "  series.id>>" + series.id);
				return parseInt(s.tmdbID) === series.tmdbID;
			})
		);

		if (watchlist._id !== watchlistID && !exists) {
			return (
				<Button
					key={watchlist._id}
					justifyContent="space-between"
					rightIcon={<AddIcon />}
					colorScheme="primary"
					mb="3"
					onClick={() => {
						handleMoveSeries(watchlist);
					}}
				>
					<Text isTruncated>{watchlist.name}</Text>
				</Button>
			);
		}
	};

	return (
		<Flex justify="center" alignItems="center" p="3">
			<Stack direction={["column", "row"]} spacing="24px">
				<Button
					leftIcon={<DeleteIcon />}
					onClick={() => {
						handleRemoveSeries();
					}}
					colorScheme="red"
				>
					Remove
				</Button>
				<Popover>
					<PopoverTrigger>
						<Button>Move</Button>
					</PopoverTrigger>
					<Portal>
						<PopoverContent>
							<PopoverArrow />
							<PopoverHeader>Move to</PopoverHeader>
							<PopoverCloseButton />
							<PopoverBody>
								<Flex direction="column" p="3">
									{watchlists
										? watchlists.map((watchlist) => renderPopoverButtons(watchlist))
										: "Loading..."}
								</Flex>
							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			</Stack>
		</Flex>
	);
}

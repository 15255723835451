import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function SeasonCard({ season }) {
	const airDate = season ? new Date(season.air_date).toDateString().slice(4) : "";
	return season ? (
		<Flex px={2} pb="4" w={["350px"]} minH="192px">
			<Box p={2} bg={"primary.300"} w="350px" borderWidth="1px" shadow="lg">
				<HStack alignItems="start" p="1">
					<Image
						htmlHeight="100px"
						htmlWidth="100px"
						src={`https://image.tmdb.org/t/p/w185/${season.poster_path}`}
						alt={`Picture of ${season.name}`}
						align="50% 50%"
					/>

					<VStack alignItems="start" spacing="1" w="295px" wrap="wrap" pr={{ base: "10", lg: "1" }}>
						<Text fontSize={["xs"]} isTruncated align="start">
							<b>{season.name}</b>
						</Text>
						<Text fontSize={["xs"]} align="start">
							<b>Air Date: </b> {airDate}
						</Text>
						<Text fontSize={["xs"]} align="start">
							<b>Total Episodes: </b> {season.episode_count}
						</Text>
						<Text noOfLines="4" align="start" fontSize={["xs"]}>
							<b>Season Overview: </b> {season.overview}
						</Text>
					</VStack>
				</HStack>
			</Box>
		</Flex>
	) : (
		"Loading.."
	);
}

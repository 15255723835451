import { Box, Button, Flex, Heading, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import InputFinal from "../components/InputFinal";

export default function SignUpForm({ onSubmit, loading, responseError }) {
	const {
		register,
		handleSubmit,
		formState: { errors, touchedFields },
	} = useForm();
	return (
		<Flex align={"center"} justify={"center"}>
			<Stack
				spacing={8}
				mx={"auto"}
				maxW={"lg"}
				py={12}
				px={6}
				bg={useColorModeValue("gray.50", "gray.800")}
			>
				<Stack align={"center"}>
					<Heading fontSize={"2xl"} color={"gray.600"}>
						Sign In to create watchlists! ✌️
					</Heading>
				</Stack>
				<Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
					<Stack spacing={4}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<InputFinal
								type="email"
								label="Email"
								isRequired={true}
								rhfInputs={{
									...register("email", { required: "Email is required!", maxLength: 80 }),
								}}
								placeholder="Email"
								touched={touchedFields.email ? true : false}
								error={errors.email}
							/>
							<InputFinal
								type="password"
								label="Password"
								placeholder="Password"
								rhfInputs={{
									...register("password", {
										required: "Password required",
										minLength: { value: 6, message: "Password must be minimum 6 characters long!" },
									}),
								}}
								isRequired={true}
								touched={touchedFields.password ? true : false}
								error={errors.password}
							/>
							<Stack>
								{!responseError.success && !loading ? (
									<Text color="red" pb="4">
										{responseError.error}
									</Text>
								) : (
									""
								)}
								<Button
									bg={"blue.400"}
									color={"white"}
									_hover={{
										bg: "blue.500",
									}}
									type="submit"
									isLoading={loading}
									loadingText="Signing In"
								>
									Sign In
								</Button>
							</Stack>
						</form>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	);
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import seriesify from "../api/seriesify";

export const getTrending = createAsyncThunk("trending/getTrending", async () => {
	const response = await seriesify.get(`/tmdb/trending/weekly/1`);

	return response.data;
});

export const getTrendingNext = createAsyncThunk("trending/getTrendingNext", async (params) => {
	const response = await seriesify.get(`/tmdb/trending/weekly/${params}`);
	return response.data;
});

const trendingSlice = createSlice({
	name: "trending",
	initialState: {
		page: 0,
		results: null,
		total_pages: 0,
		total_results: 0,
	},

	reducers: {
		updateTrending: (state, action) => {
			const updatedTrending = action.payload;
			console.log(updatedTrending);
			state.trending = updatedTrending;
		},
	},
	extraReducers: {
		[getTrending.fulfilled]: (state, action) => {
			state.page = action.payload.page;
			state.results = action.payload.results;
			state.total_pages = action.payload.total_pages;
			state.total_results = action.payload.total_results;
		},
		[getTrendingNext.fulfilled]: (state, action) => {
			state.page = action.payload.page;
			state.results = state.results.concat(action.payload.results);
			state.total_pages = action.payload.total_pages;
			state.total_results = action.payload.total_results;
		},
	},
});

export const { updateTrending } = trendingSlice.actions;

export default trendingSlice.reducer;

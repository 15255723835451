import { Route } from "react-router-dom";
import SearchResult from "../pages/SearchResult";
import Series from "../pages/Series";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Trending from "../pages/Trending";

export const PublicRoutes = () => {
	return (
		<div>
			<Route path="/" exact component={Trending}></Route>
			<Route path="/signin" exact component={SignIn}></Route>
			<Route path="/signup" exact component={SignUp}></Route>
			<Route path="/trending" exact component={Trending}></Route>
			<Route path="/search/:searchTerm" exact component={SearchResult}></Route>
			<Route path="/series/:tmdbID" exact component={Series}></Route>
		</div>
	);
};

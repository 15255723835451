import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
	Button,
	Flex,
	IconButton,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	Text,
} from "@chakra-ui/react";
import React from "react";
import { BsBookmarkPlus } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { addSeriesToWatchlist, removeSeriesFromWatchlist } from "../store//watchlistSlice";

export default function AddSeries({ watchlists, series, iconButton }) {
	const dispatch = useDispatch();

	const userID = useSelector((state) => state.user.data._id);

	const seriesObj = (watchlistID) => {
		return {
			watchlistID: watchlistID,
			userID: userID,
			series: {
				tmdbID: series.id,
				name: series.name,
				poster_path: series.poster_path,
				first_air_date: series.first_air_date,
				vote_average: series.vote_average,
				vote_count: series.vote_count,
				overview: series.overview,
				original_name: series.original_name,
			},
		};
	};

	const handleAddSeries = (watchlist) => {
		dispatch(addSeriesToWatchlist(seriesObj(watchlist._id)));
		// watchlist.seriesList.push(seriesObj("sd").series);
		// setWatchlistsState(watchlist);
	};
	const handleRemoveSeries = (watchlist) => {
		dispatch(removeSeriesFromWatchlist({ seriesID: series.id, watchlistID: watchlist._id }));
	};

	const renderButtons = (watchlist) => {
		const exists = Boolean(
			watchlist.seriesList.find((s) => {
				// console.log(series.tmdbID + "<< series.tmdbID" + "  series.id>>" + series.id);
				return parseInt(s.tmdbID) === series.id;
			})
		);

		if (exists) {
			return (
				<Button
					key={watchlist._id}
					justifyContent="space-between"
					rightIcon={<DeleteIcon />}
					colorScheme="red"
					mb="3"
					onClick={() => {
						handleRemoveSeries(watchlist);
					}}
				>
					<Text isTruncated>{watchlist.name}</Text>
				</Button>
			);
		} else {
			return (
				<Button
					key={watchlist._id}
					justifyContent="space-between"
					rightIcon={<AddIcon />}
					colorScheme="primary"
					mb="3"
					onClick={() => {
						handleAddSeries(watchlist);
					}}
				>
					<Text isTruncated>{watchlist.name}</Text>
				</Button>
			);
		}
	};

	return (
		<Popover strategy="fixed">
			<PopoverTrigger>
				{iconButton ? (
					<IconButton colorScheme="gray" size="md" icon={<BsBookmarkPlus />}></IconButton>
				) : (
					<Button colorScheme="primary" _focus={{ boxShadow: "none" }}>
						Add to
					</Button>
				)}
			</PopoverTrigger>
			<Portal>
				<PopoverContent>
					<PopoverArrow />
					<PopoverHeader bg="primary.300">Add to</PopoverHeader>
					<PopoverCloseButton />
					<PopoverBody bg="primary.200">
						<Flex direction="column" p="3">
							{watchlists ? watchlists.map((watchlist) => renderButtons(watchlist)) : "Loading..."}
						</Flex>
					</PopoverBody>
				</PopoverContent>
			</Portal>
		</Popover>
	);
}

import { Box, Flex } from "@chakra-ui/react";
import jwt from "jwt-decode";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router } from "react-router-dom";
import seriesify from "./api/seriesify";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import history from "./history";
import { AppRoutes } from "./routes";
import { updateUserFromStorage } from "./store/userSlice";
import { getAllWatchlist } from "./store/watchlistSlice";

function App() {
	const tokenInState = useSelector((state) => state.user.token);

	const token = localStorage.getItem("token");
	const user = token ? jwt(token) : null;
	const dispatch = useDispatch();

	useEffect(() => {
		if (!tokenInState && user) {
			dispatch(updateUserFromStorage({ user: user, token: token }));
			seriesify.defaults.headers.common["jwt-token"] = token;
			dispatch(getAllWatchlist(user.user._id));
		}
	}, [user, token, dispatch, tokenInState]);

	return (
		<div className="App">
			<Box background="primaryBgColor">
				<Flex
					direction="column"
					// ml={{ lg: "10vw", xl: "15vw" }}
					// mr={{ lg: "10vw", xl: "15vw" }}
					background="secondaryColor"
				>
					<Router history={history}>
						<NavBar />
						<Flex
							direction="column"
							// justifyContent="center"
							// alignItems="center"
							pt={{ base: "125", lg: "100" }}
							px="10"
							pb="10"
							minH={"100vh"}
						>
							<AppRoutes />
						</Flex>
						<Footer />
					</Router>
				</Flex>
			</Box>
		</div>
	);
}

export default App;

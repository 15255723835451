import { Flex } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import CreateWatchlist from "../components/CreateWatchlist";
import ShowWatchlists from "../components/ShowWatchlists";

export default function Watchlists() {
	const userID = useSelector((state) => state.user.data._id);

	return (
		<Flex justify="center">
			<ShowWatchlists />
			<CreateWatchlist userID={userID} />
		</Flex>
	);
}

import { CloseIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	Center,
	Container,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	HStack,
	IconButton,
	Image,
	Input,
	InputGroup,
	InputLeftElement,
	Spacer,
	Text,
	useColorModeValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react";
import { IoIosList, IoMdTrendingUp } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import AnonymousLogin from "./AnonymousLogin";
import SignOutButton from "./SignOutButton";
const Links = [
	{
		name: "Trending",
		link: "/trending",
	},
];

const NavLink = ({ children, mobile, icon }) => (
	<Box
		px={2}
		py={{ base: "4", lg: "2" }}
		rounded="md"
		_hover={{
			textDecoration: "none",
			bg: useColorModeValue("secondaryColor", "primaryTextColor"),
		}}
	>
		<Link to={children.link}>
			{mobile ? (
				<Button
					leftIcon={icon}
					iconSpacing="6"
					fontSize={{ base: "2xl", lg: "md" }}
					color="primaryTextColor"
					bg="none"
				>
					{children.name}
				</Button>
			) : (
				<Text color="primaryTextColor">{children.name}</Text>
			)}
		</Link>
	</Box>
);

export default function NavBar() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const user = useSelector((state) => state.user);

	//TODO: clear time out doesnt work, try using useEffect

	const search = (term) => {
		console.log(term.target.value.trim().length > 0);
		if (term.target.value.trim().length > 0) {
			const timeoutID = setTimeout(() => {
				history.push(`/search/${term.target.value}`);
				// console.log(term.target.value);
			}, 1200);

			return () => clearTimeout(timeoutID);
		} else {
			history.push("/");
		}
	};

	return (
		<Flex justify="start" pos="fixed" top="0" zIndex="999">
			<Box bg="primaryColor" px={4} w="100vw">
				<Flex h={{ base: "24", lg: "16" }} alignItems="center" justifyContent="start">
					<Container>
						<IconButton
							size="lg"
							icon={
								isOpen ? <CloseIcon color="primaryColor" /> : <HamburgerIcon color="primaryColor" />
							}
							bg="primary.400"
							aria-label="Open Menu"
							display={{ lg: "none" }}
							onClick={onOpen}
							_focus={{ boxShadow: "none" }}
						/>
						<HStack spacing={8} alignItems="center" display={{ base: "none", lg: "flex" }}>
							<Box>
								<Link to="/">
									<Image maxW="35" maxH="35" boxSize="xs" src="/logo.png"></Image>
								</Link>
							</Box>
							<HStack as="nav" spacing={4}>
								{Links.map((link) => (
									<NavLink key={link.link}>{link}</NavLink>
								))}
								{user.authenticated ? (
									<HStack as="nav" spacing={4}>
										<NavLink key="Watchlist">
											{{
												name: "Watchlist",
												link: "/watchlists",
											}}
										</NavLink>
									</HStack>
								) : (
									<HStack as="nav" spacing={4}>
										<NavLink key="SignUp">
											{{
												name: "SignUp",
												link: "/signup",
											}}
										</NavLink>
										<NavLink key="SignIn">
											{{
												name: "SignIn",
												link: "/signin",
											}}
										</NavLink>
									</HStack>
								)}
							</HStack>
						</HStack>
					</Container>
					<Spacer />

					<InputGroup w="lg" mr={{ base: 10, lg: "0" }}>
						<InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
						<Input
							color="white"
							type="search"
							onChange={(term) => search(term)}
							placeholder="Search"
						/>
					</InputGroup>
					<Spacer />

					<Container mr="50px" display={{ base: "none", lg: "block" }}>
						<HStack justify="end">
							{user.authenticated ? (
								<HStack spacing="10">
									<Text color="primaryTextColor">{"Welcome " + user.data.firstName}</Text>
									<SignOutButton />
								</HStack>
							) : (
								<AnonymousLogin />
							)}
						</HStack>
					</Container>
					{/* <Flex alignItems="center">
						<Menu>
							<MenuButton as={Button} rounded="full" variant="link" cursor="pointer" minW={0}>
								<Avatar
									size="sm"
									src="https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
								/>
							</MenuButton>
							<MenuList bg="secondaryColor">
								<MenuItem>Link 1</MenuItem>
								<MenuItem>Link 2</MenuItem>
								<MenuDivider />
								<MenuItem>Link 3</MenuItem>
							</MenuList>
						</Menu>
					</Flex> */}
				</Flex>
				<Drawer
					placement={"left"}
					onClose={onClose}
					isOpen={isOpen}
					size="md"
					colorScheme="primaryColor"
				>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerHeader py="7" mb="6">
							{user.authenticated ? (
								<HStack justify="space-between">
									<Box>
										<Link to="/">
											<Image maxW="35" maxH="35" boxSize="xs" src="/logo.png"></Image>
										</Link>
									</Box>
									<Text color="primaryTextColor">{"Welcome " + user.data.firstName}</Text>
									<SignOutButton />
								</HStack>
							) : (
								<HStack justify="center">
									<Box>
										<Link to="/">
											<Image maxW="35" maxH="35" boxSize="xs" src="/logo.png"></Image>
										</Link>
									</Box>
								</HStack>
							)}
						</DrawerHeader>
						<DrawerBody>
							<Box pb={4} display={{ lg: "none" }}>
								{user.authenticated ? (
									<VStack as="nav" spacing={4} justify="stretch" alignItems="start">
										{Links.map((link) => (
											<Box w="md" mt="2" onClick={onClose} key={link.link}>
												<NavLink mobile={true} icon={<IoMdTrendingUp size="50" />}>
													{link}
												</NavLink>
											</Box>
										))}

										<Box w="md" onClick={onClose}>
											<NavLink mobile={true} icon={<IoIosList size="50" />} key="Watchlist">
												{{
													name: "Watchlist",
													link: "/watchlists",
												}}
											</NavLink>
										</Box>
									</VStack>
								) : (
									<VStack as="nav" spacing={4} justify="stretch" alignItems="start">
										{Links.map((link) => (
											<Box w="md" onClick={onClose} key={link.link}>
												<NavLink mobile={true}>{link}</NavLink>
											</Box>
										))}
										<Box w="md" onClick={onClose}>
											<NavLink mobile={true} key="SignUp">
												{{
													name: "SignUp",
													link: "/signup",
												}}
											</NavLink>
										</Box>
										<Box w="md" onClick={onClose}>
											<NavLink mobile={true} key="SignIn">
												{{
													name: "SignIn",
													link: "/signin",
												}}
											</NavLink>
										</Box>
										<Center w="md" pt="6">
											<AnonymousLogin />
										</Center>
									</VStack>
								)}
							</Box>
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</Box>
		</Flex>
	);
}

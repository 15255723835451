import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./searchSlice";
import seriesReducer from "./seriesSlice";
import trendingReducer from "./trendingSlice";
import userReducer from "./userSlice";
import watchlistReducer from "./watchlistSlice";

export default configureStore({
	reducer: {
		trending: trendingReducer,
		search: searchReducer,
		user: userReducer,
		watchlist: watchlistReducer,
		series: seriesReducer,
	},
	devTools: { trace: true, traceLimit: 25 },
});

import { AddIcon } from "@chakra-ui/icons";
import {
	Button,
	FormControl,
	FormLabel,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import { default as React, useState } from "react";
import { useDispatch } from "react-redux";
import { createWatchlist } from "../store/watchlistSlice";

export default function CreateWatchlist({ userID }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = React.useRef();
	const dispatch = useDispatch();
	const [watchlistName, setWatchlistName] = useState("");

	const handleSave = (name) => {
		if (watchlistName) {
			dispatch(createWatchlist({ name: watchlistName, userID: userID }));
			onClose();
		}
	};

	return (
		<>
			<IconButton
				isRound
				top={28}
				right={14}
				position="absolute"
				colorScheme="primary"
				aria-label="Create a Watchlist"
				size="lg"
				icon={<AddIcon />}
				onClick={onOpen}
			/>

			<Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader bg="primary.400">Create a Watchlist</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={5} bg="primary.200">
						<FormControl>
							<FormLabel>Watchlist Name</FormLabel>
							<Input
								onChange={(e) => setWatchlistName(e.target.value)}
								ref={initialRef}
								placeholder="Watchlist Name"
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter bg="primary.200">
						<Button colorScheme="blue" mr={3} onClick={handleSave}>
							Create
						</Button>
						<Button onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

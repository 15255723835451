import { default as React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SeriesDetails from "../components/series/seriesDetails/SeriesDetails";
import SeriesDetailsSkeleton from "../components/skeletons/SeriesDetailsSkeleton";
import { getCast, getSeries, getSimilar, getTrailer } from "../store/seriesSlice";

export default function Series(props) {
	const dispatch = useDispatch();
	const tmdbID = props.match.params.tmdbID;
	const series = useSelector((state) => state.series.data);
	const cast = useSelector((state) => state.series.cast);
	const similars = useSelector((state) => state.series.similars);
	const loading = useSelector((state) => state.series.loading);
	const watchlists = useSelector((state) => state.watchlist.all.watchlists);

	useEffect(() => {
		dispatch(getSeries(tmdbID));
		dispatch(getCast(tmdbID));
		dispatch(getSimilar(tmdbID));
		dispatch(getTrailer(tmdbID));
	}, [dispatch, tmdbID]);

	return loading ? (
		<SeriesDetailsSkeleton />
	) : (
		<SeriesDetails series={series} cast={cast} similars={similars} watchlists={watchlists} />
	);
}

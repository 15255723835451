import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { default as React } from "react";
import AddSeries from "../../AddSeries";
import SeriesDetailsSkeleton from "../../skeletons/SeriesDetailsSkeleton";
import Rating from "../RatingView";
import CastCarousel from "./CastCarousel";
import LatestEpisodesDropdown from "./LatestEpisodesDropdown";
import SeasonsCarousel from "./SeasonsCarousel";
import SimilarSeriesCrousel from "./SimilarSeriesCrousel";
import TrailerModalButton from "./TrailerModalButton";

export default function SeriesDetails({ series, cast, similars, watchlists }) {
	const firstAirDate = series ? new Date(series.first_air_date).toDateString().slice(4) : "";
	const lastAirDate = series ? new Date(series.last_air_date).toDateString().slice(4) : "";
	const genres = series.genres ? series.genres.map((genre) => genre.name).join(", ") : "";
	const runtime = series.episode_run_time
		? series.episode_run_time.join(", ")
		: series.episode_run_time;
	return series ? (
		<Flex
			direction="column"
			justify="center"
			alignItems="center"
			mx={{ lg: "2vw" }}
			bg="primary.400"
			w="91vw"
		>
			<Flex
				w="89vw"
				justify={{ base: "start", lg: "start" }}
				alignItems={{ base: "start", lg: "start" }}
				direction="row"
				grow="1"
				bg="primary.400"
			>
				<Box
					pl="2"
					py="5"
					ml="2"
					my="5"
					minW="sm"
					maxW="md"
					alignItems="start"
					mr={{ md: "14", lg: "10" }}
				>
					<Image
						minH="sm"
						minW="sm"
						src={
							series.poster_path
								? `https://image.tmdb.org/t/p/w780/${series.poster_path}`
								: "https://image.tmdb.org/t/p/w1280//lJA2RCMfsWoskqlQhXPSLFQGXEJ.jpg"
						}
						alt={`Picture of ${series.name}`}
					/>
				</Box>
				<Flex p="4" m="3" lineHeight="tight" minW="sm" w="auto" grow="1" pr="2vw">
					<VStack grow="1" alignItems="start">
						<HStack>
							<Box fontWeight="bold" as="h4" mr="auto">
								<Text fontSize={["xl", "2xl", "3xl"]}>{series.name}</Text>{" "}
							</Box>
							<Flex p="3" justify="center">
								{watchlists ? <AddSeries watchlists={watchlists} series={series} /> : ""}
							</Flex>
						</HStack>
						<Rating
							vote_average={series.vote_average}
							vote_count={series.vote_count}
							stars={10}
						></Rating>
						<Text fontSize={["lg", "xl", "2xl"]}>
							<b>Overview:</b> {series.overview}
						</Text>
						<Text fontSize={["lg", "xl", "2xl"]}>
							<b>Episode Run Time:</b> {runtime} minutes
						</Text>
						<Text fontSize={["lg", "xl", "2xl"]}>
							<b>First Air Date:</b> {firstAirDate}
						</Text>
						<Text fontSize={["lg", "xl", "2xl"]}>
							<b>Last Air Date:</b> {lastAirDate}
						</Text>{" "}
						<Text fontSize={["lg", "xl", "2xl"]}>
							<b>Genres:</b> {genres.toString()}
						</Text>
						<Text fontSize={["lg", "xl", "2xl"]}>
							<b>Total Seasons:</b> {series.number_of_seasons}
						</Text>
						<Text fontSize={["lg", "xl", "2xl"]}>
							<b>Total Episodes:</b> {series.number_of_episodes}
						</Text>
						<Text fontSize={["lg", "xl", "2xl"]}>
							<b>Tagline:</b> {series.tagline}
						</Text>
						<Text fontSize={["lg", "xl", "2xl"]} pb="4">
							<b>Status:</b> {series.status}
						</Text>
						<TrailerModalButton />{" "}
					</VStack>
				</Flex>
			</Flex>
			<CastCarousel cast={cast} />
			<LatestEpisodesDropdown
				episodes={{
					next_episode_to_air: series.next_episode_to_air,
					last_episode_to_air: series.last_episode_to_air,
				}}
			/>
			<SeasonsCarousel seasons={series.seasons} />
			<SimilarSeriesCrousel similars={similars} watchlists={watchlists} />
		</Flex>
	) : (
		<SeriesDetailsSkeleton />
	);
}
